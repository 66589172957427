<template>
  <RoleForm />
</template>

<script>
import RoleForm from '../../components/roles/RoleForm';

export default {
  components: { RoleForm },
};
</script>
